import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Home from './components/Home';
import Ha from './components/Ha';
import Rc from './components/Rc';
import Cr from './components/Cr';
import Uhabit from './components/Uhabit';
import RhymeCard from './components/RhymeCard';
// import LocalHtmlComponent from './components/LocalHtmlComponent';

function App() {
  return (
    <div>
      <RecoilRoot>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/rc" element={<Rc />} />
            <Route path="/cr" element={<Cr />} />
            <Route path="/ha" element={<Ha />} />
            <Route path="/uhabit" element={<Uhabit />} />
            <Route path="/rhymecard" element={<RhymeCard />} />
          </Routes>
        </Router>
      </RecoilRoot>
    </div>
  );
}

export default App;
