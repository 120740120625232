import React, { useEffect, useState } from 'react';

function RhymeCard() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    console.log("실행")
    console.log("process.env.PUBLIC_URL : " + process.env.PUBLIC_URL)
    fetch('/main/hp0621/index.html')
      .then(response => response.text())
      .then(data => {
        setHtmlContent(data);
      });
  }, []);

    
  return (

    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
}

export default RhymeCard;

